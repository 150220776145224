import Vue from 'vue';

import { uniq, isEmpty, isEqual, isNil } from 'lodash-es';

import { activityService, customerService, invoiceService } from '@services';
import { i18n } from '@plugins';

export default {
  fetchActivityLogData: async (
    { commit, state, rootGetters },
    { companyId, invoiceId, customerId, filters = {}, timestamp },
  ) => {
    try {
      commit('settings/LOADING_STATUS', true, { root: true });

      if (isNil(timestamp)) {
        commit('RESET_ACTIVITY_LOG_DATA', true);
      }

      const { activityLogData, page } = state;

      const perPage = 25;

      const payload = {
        excludeTemplateBody: true,
        excludeEvents: true,
        companyId,
        timestamp,
        perPage,
        sort: { timestamp: -1 },
        search: {
          invoiceIds: invoiceId ? [invoiceId] : undefined,
          customerIds: customerId ? [customerId] : undefined,
          ...filters,
          isTest: rootGetters['settings/uiShowTestActivityLog'] ? undefined : false,
        },
      };

      const { list, stats } = filters.onlyWithActions
        ? await activityService.actionsList(payload)
        : await activityService.list(payload);

      const activityLogs = !isEmpty(activityLogData) ? [...activityLogData, ...list] : [...list];

      commit('FETCH_ACTIVITY_LOG_LIST', activityLogs);
      commit('SET_PAGES_COUNT', Math.ceil(stats.totalCount / perPage));

      if (isEqual(page, 0)) {
        commit('SET_ACTIVITY_COUNT', stats.totalCount);
      }

      commit('SET_PAGE', page + 1);
    } catch ({ message }) {
      console.error(message);
    } finally {
      commit('settings/LOADING_STATUS', false, { root: true });
    }
  },
  fetchActivityLogDataInvoices: async ({ commit, state }, { companyId }) => {
    try {
      commit('settings/LOADING_STATUS', true, { root: true });

      const { activityLogData } = state;

      if (Array.isArray(activityLogData) && activityLogData.length > 0) {
        const invoiceIds = uniq(
          [
            ...activityLogData.filter(({ invoiceId }) => !!invoiceId).map(({ invoiceId }) => invoiceId),
            ...activityLogData
              .filter(({ allocation }) => !isNil(allocation))
              .map(({ allocation }) => allocation.invoiceIds)
              .flat(),
            ...activityLogData
              .filter(({ oldAllocation }) => !isNil(oldAllocation))
              .map(({ oldAllocation }) => oldAllocation.invoiceIds)
              .flat(),
          ].filter(Boolean),
        );
        const customerIds = uniq(
          [
            ...activityLogData.filter(({ customerId }) => !!customerId).map(({ customerId }) => customerId),
            ...activityLogData
              .filter(({ allocation }) => !isNil(allocation))
              .map(({ allocation }) => allocation.customerId),
            ...activityLogData
              .filter(({ oldAllocation }) => !isNil(oldAllocation))
              .map(({ oldAllocation }) => oldAllocation.customerId),
          ].filter(Boolean),
        );

        if (!isEmpty(invoiceIds)) {
          await invoiceService
            .list({
              companyId,
              search: { ids: invoiceIds, status: 'all' },
            })
            .then(({ list: invoices }) => commit('FETCH_ACTIVITY_LOG_INVOICES_LIST', invoices));
        }

        if (!isEmpty(customerIds)) {
          await customerService
            .list({
              companyId,
              search: { ids: customerIds },
            })
            .then(({ list: customers }) => commit('FETCH_ACTIVITY_LOG_CUSTOMERS_LIST', customers));
        }
      }
    } catch ({ message }) {
      console.error(message);
    } finally {
      commit('settings/LOADING_STATUS', false, { root: true });
    }
  },
  addActivityLogNoteData: async (
    { commit, dispatch },
    {
      companyId,
      invoiceId,
      customerId,
      disputeId,
      body,
      type,
      workflowEnabled,
      actions,
      parentDetails,
      userId,
      isTest,
      promiseToPayDate,
      pauseWorkflow,
      activateAtDate,
      clearPromiseToPay,
      callStarted,
      callEnded,
      callUnanswered,
    },
  ) => {
    let message = i18n.t('notifications.message');

    try {
      commit('settings/LOADING_STATUS', true, { root: true });

      await activityService.createNote({
        companyId,
        notes: [
          {
            invoiceId,
            customerId,
            disputeId,
            body,
            type,
            workflowEnabled,
            actions,
            parentDetails,
            userId,
            isTest,
            promiseToPayDate,
            pauseWorkflow,
            activateAtDate,
            clearPromiseToPay,
            callStarted,
            callEnded,
            callUnanswered,
          },
        ],
      });

      if (isEqual(type, 'workflowEvent')) {
        message = i18n.t('notifications.workflow');
      }

      dispatch(
        'notifications/presentNotification',
        {
          type: 'success',
          visible: true,
          message,
        },
        { root: true },
      );
    } catch ({ message }) {
      console.error(message);
    } finally {
      commit('settings/LOADING_STATUS', false, { root: true });
    }
  },
  markAsDone: async ({ dispatch }, _this) => {
    try {
      await activityService.markAsDone(_this.markAsDonePayload);

      await _this.$listeners.fetchActivity();

      dispatch(
        'notifications/presentNotification',
        {
          type: 'success',
          visible: true,
          message: i18n.t('notifications.message'),
        },
        { root: true },
      );
    } catch (message) {
      dispatch(
        'notifications/presentNotification',
        {
          type: 'error',
          visible: true,
          message,
        },
        { root: true },
      );
    }
  },
  deleteNote: async ({ dispatch }, { _id: activityLogId, isTimelineItem = false, _this }) => {
    try {
      const { deletedActivities } = await activityService.deleteNote({
        activityLogId,
        companyId: Vue.auth.user()?.selectedCompany?.id,
      });

      await _this.$listeners.fetchData();

      if (isTimelineItem) {
        await _this.$listeners.fetchActivity();
      }

      dispatch(
        'notifications/presentNotification',
        {
          type: 'success',
          visible: true,
          message: i18n.t('notifications.noteDeleted'),
        },
        { root: true },
      );

      return deletedActivities?.deletedCount;
    } catch (message) {
      dispatch(
        'notifications/presentNotification',
        {
          type: 'error',
          visible: true,
          message,
        },
        { root: true },
      );
    }
  },
  async clearInvoicesPayment({ dispatch }, { invoiceIds, submitId, isBulk }) {
    try {
      await activityService.clearInvoicesPayment({ companyId: Vue.auth.user()?.selectedCompany?.id, invoiceIds });

      this._vm.$VueAnalytics.track({
        eventName: 'clear_payment_response',
        event_uuid: '00bd87a8-1b99-41c4-9023-6495678b5114',
        type: 'modal',
        action: 'response',
        category: 'clear_payment',
        status: 'success',
        submit_id: submitId,
        boolean_field_name: 'multiple_selected',
        boolean_field_value: isBulk,
      });

      dispatch(
        'notifications/presentNotification',
        {
          type: 'success',
          visible: true,
          message: i18n.t('notifications.message'),
        },
        { root: true },
      );
    } catch (message) {
      this._vm.$VueAnalytics.track({
        eventName: 'clear_payment_response',
        event_uuid: '0cdf03c2-8f8f-4a35-b2e8-996b764a1a27',
        type: 'modal',
        action: 'response',
        category: 'clear_payment',
        status: 'fail',
        error_message: message,
        submit_id: submitId,
        boolean_field_name: 'multiple_selected',
        boolean_field_value: isBulk,
      });

      dispatch(
        'notifications/presentNotification',
        {
          type: 'error',
          visible: true,
          message,
        },
        { root: true },
      );
    }
  },
  exportActivityLog: async ({ commit }, payload) => {
    try {
      commit('settings/LOADING_STATUS', true, { root: true });

      return await activityService.list(payload);
    } catch ({ message }) {
      console.error(message);
    } finally {
      commit('settings/LOADING_STATUS', false, { root: true });
    }
  },
  resetActivityLogData({ commit }) {
    commit('RESET_ACTIVITY_LOG_DATA');
  },
  fetchActivityLog: async ({}, payload) => {
    try {
      return await activityService.findById(payload);
    } catch ({ message }) {
      console.error(message);
    }
  },
  sendReplyEmail: async ({}, payload) => {
    try {
      return await activityService.sendReplyEmail(payload);
    } catch ({ message }) {
      console.error(message);
    }
  },
  fetchEmailEventMetrics: async ({}, payload) => {
    try {
      return await activityService.emailEventMetrics({
        ...payload,
        companyId: Vue.auth.user()?.selectedCompany?.id,
      });
    } catch ({ message }) {
      console.error(message);
    }
  },
};
